import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { AvatarGroup, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import logo from '../homerun-derby-pic.jpg';
import yankees from '../yankees.png';
import twins from '../twins.png';
import phillies from '../phillies.png';
import athletics from '../athletics.png';
import cardinals from '../cardinals.png';
import dodgers from '../dodgers.png';

import astros from '../astros.png';
import bluejays from '../bluejays.png';
import braves from '../braves.png';
import cubs from '../cubs.png';
import giants from '../giants.png';
import indians from '../indians.png';

import mets from '../mets.png';
import nationals from '../nationals.png';
import orioles from '../orioles.png';
import padres from '../padres.png';

import rangers from '../rangers.png';
import reds from '../reds.png';
import rays from '../rays.png';
import redsox from '../redsox.png';

import rockies from '../rockies.png';
import whitesox from '../whitesox.png';
import angels from '../angels.png';
import tigers from '../tigers.png';
import brewers from '../brewers.png';
import marlins from '../marlins.png';
import mariners from '../mariners.png';
import royals from '../royals.png';








function PlayerDetailsCard({ player, index }) {




    return (


        <div>
            <Card style={{ backgroundColor: index < 5 ? 'lightgray' : 'darkgray' }}>
                <CardHeader
                    avatar={
                        <AvatarGroup style={{ spacing: 'large' }} >
                           
                            <Avatar src={player.playerTeam === 'New York Yankees' ? yankees :
                                         player.playerTeam === 'Los Angeles Dodgers' ? dodgers:
                                         player.playerTeam === 'Minnesota Twins' ? twins:
                                         player.playerTeam === 'Philadelphia Phillies' ? phillies:
                                         player.playerTeam === 'Oakland As' ? athletics:
                                         player.playerTeam === 'St Louis Cardinals' ? cardinals:

                                         player.playerTeam === 'Houston Astros' ? astros:
                                         player.playerTeam === 'Toronto Blue Jays' ? bluejays:
                                         player.playerTeam === 'Atlanta Braves' ? braves:
                                         player.playerTeam === 'Chicago Cubs' ? cubs:
                                         player.playerTeam === 'San Francisco Giants' ? giants:
                                         player.playerTeam === 'Cleveland Guardians' ? indians:

                                         player.playerTeam === 'New York Mets' ? mets:
                                         player.playerTeam === 'Washington Nationals' ? nationals:
                                         player.playerTeam === 'Baltimore Orioles' ? orioles:
                                         player.playerTeam === 'San Diego Padres' ? padres:
                                         player.playerTeam === 'Texas Rangers' ? rangers:

                                         player.playerTeam === 'Cincinnati Reds' ? reds:
                                         player.playerTeam === 'Tampa Bay Rays' ? rays:
                                         player.playerTeam === 'Boston Red Sox' ? redsox:
                                         player.playerTeam === 'Colorado Rockies' ? rockies:
                                         player.playerTeam === 'Chicago White Sox' ? whitesox:
                                         player.playerTeam === 'Los Angeles Angels' ? angels:
                                         player.playerTeam === 'Detroit Tigers' ? tigers:
                                         player.playerTeam === 'Miami Marlins' ? marlins:
                                         player.playerTeam === 'Milwaukee Brewers' ? brewers:
                                         player.playerTeam === 'Kansas City Royals' ? royals:
                                         player.playerTeam === 'Seattle Mariners' ? mariners:

                                         logo} sx={{ width: 56, height: 56 }} />
                        </AvatarGroup>

                    }

                
                    title={player.playerName}
                    subheader={player.playerTeam}
                />
                <CardContent style={{ textAlign: 'center', fontWeight: 'bold', padding: 2 }} >
                    Daily HR: {player.dailyHR}  HR TOTAL: {player.totalHR}
                </CardContent>
            </Card>
        </div>
    )
}

export default PlayerDetailsCard;

