import React, { useState, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import ReactDOM from "react-dom";


const Home = () => {
  
  const [user, setUser] = useState('');
 
  Auth.currentAuthenticatedUser({bypassCache: true }).then((user) => {
    setUser(user.username);
  });


  return (
    

    <div style={{ textAlign: 'center' }}>

      <h3 style={{ color: '#51C786', fontWeight: 'bold' }}>
        2024 Home Run Derby Challenge
      </h3>
       <p>Welcome  {user}</p>
   
      <div style={{ textAlign: 'left', paddingLeft: 10 }}>
        <h6 style={{ color: '#51C786', fontWeight: 'bold' }}>Rules: </h6>
        <p> Draft 7 players from Major League Baseball </p>
        <p> Top 5 players home runs  count towards total </p>
        <p> No Trades </p>
        <p> No Free Agency </p>
        <p> Top 3 Places Payout 1st: $150 2nd: $50 3rd: $25</p>
        <p style={{fontWeight: 'bold' }}>
          Homerun totals will be updated each day by 9am
        </p>
        <h6 style={{ color: '#51C786', fontWeight: 'bold' }}>Draft Order</h6>
        <p>1. Nick (The Shirt)</p>
        <p>2. Kevin (My Pillow Guy)</p>
        <p>3. Todd (Blapples)</p> 
        <p>4. Jerry (Jerry's Kids)</p>
        <p>5. Dick (The Valeries) </p>
        <p>6. Greg (Greggy's Bunch)</p>
        <p>7. George (Killing Me Smalls)</p>
        <p>8. Jeff (The Dude)</p>
        <p>9. Lawrence (Team Lawrence)</p>
      </div>
    </div>
  )
}




export default Home;
