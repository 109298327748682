import React from 'react'
import history1 from '../history1.PNG'
import history2 from '../history2.PNG'
import history3 from '../history3.PNG'
import history4 from '../history4.PNG'
import history5 from '../history5.PNG'
import history6 from '../history6.PNG'
import history7 from '../history7.PNG'
import history8 from '../history8.PNG'

function History() {

  return (
    <div>
    <h1 style={{textAlign: 'center'}}>History</h1>
      <h4 style={{fontWeight: 'bold'}}>1991 - 1994
      <img src={history1} style={{height: 200, width: 400}}></img>
      </h4>
      <h4 style={{fontWeight: 'bold'}}>1995 - 1998
      <img src={history2} style={{height: 200, width: 400}}></img>
      </h4>
      <h4 style={{fontWeight: 'bold'}}>1999 - 2002
      <img src={history3} style={{height: 200, width: 400}}></img>
      </h4>
      <h4 style={{fontWeight: 'bold'}}>2003 - 2006
      <img src={history4} style={{height: 200, width: 400}}></img>
      </h4>
      <h4 style={{fontWeight: 'bold'}}>2007 - 2010
      <img src={history5} style={{height: 200, width: 400}}></img>
      </h4>
      <h4 style={{fontWeight: 'bold'}}>2011 - 2014
      <img src={history6} style={{height: 200, width: 400}}></img>
      </h4>
      <h4 style={{fontWeight: 'bold'}}>2005 - 2018
      <img src={history7} style={{height: 200, width: 400}}></img>
      </h4>
      <h4 style={{fontWeight: 'bold'}}>2019 - 2021
      <img src={history8} style={{height: 200, width: 400}}></img>
      </h4>
     
    </div>
  )
  }

export default History