import React from 'react';
import { Link } from 'react-router-dom';

import { Authenticator } from '@aws-amplify/ui-react';
import LogoutIcon from '@mui/icons-material/Logout';
import HistoryIcon from '@mui/icons-material/History';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import Groups from '@mui/icons-material/Groups';
import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TodayIcon from '@mui/icons-material/Today';

import { useState } from 'react';
import { Auth } from 'aws-amplify';

var style = {
    backgroundColor: "black",
    borderTop: "1px solid black",
    textAlign: "center",
    padding: "10px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "90px",
    width: "100%",
}

var phantom = {
    display: 'block',
    padding: '0px',
    height: '90px',
    width: '100%',
}




const Footer = () => {

    const [signedInUser, setSignedInUser] = useState('');
    const adminRole = ['jralph', 'jeffr'];
 
  Auth.currentAuthenticatedUser({bypassCache: true }).then((signedInUser) => {
    setSignedInUser(signedInUser.username);
  });

    
    return (
        
        <Authenticator>
            {({ signOut, user }) => (
                <footer className="main-head">

                    <div style={phantom} />
                    <div style={style}>

                        <span  style={{ paddingLeft: 5 }}>
                            <Link  to='/home'>
                                <SportsBaseballIcon  sx={{  fontSize: 38, color: 'gray' }} />
                            </Link>
                        </span>
                        
                        <span style={{ paddingLeft: 10 }}>
                            
                            <Link to='/teams'>
                                <EmojiEventsIcon  sx={{ fontSize: 38, color: 'gray' }} />
                            </Link>
                        </span>

                      
                        <span style={{ paddingLeft: 10 }}>
                            <Link to='/dailyHomeRuns'>
                                <TodayIcon sx={{ fontSize: 38, color: 'gray' }} />
                            </Link>
                        </span>
                           


                        <span  style={{ paddingLeft: 10 }}>
                            <Link  to='/allPlayers'>
                                <Groups  sx={{  fontSize: 38, color: 'gray' }} />
                            </Link>
                        </span>

                        <span  style={{ paddingLeft: 10 }}>
                            <Link to='/history'>
                                <HistoryIcon  sx={{  fontSize: 38, color: 'gray' }} />
                            </Link>
                       
                        </span>
                        
                        {adminRole.includes(signedInUser) && 
                        <span style={{ paddingLeft: 10 }}>
                            <Link to='/updatePlayerHR'>
                                <EditIcon sx={{ fontSize: 38, color: 'gray' }} />
                            </Link>
                        </span>
                           }

                        {adminRole.includes(signedInUser) && 
                        <span style={{ paddingLeft: 10 }}>
                            <Link to='/updateTeamHR'>
                                <EditOutlinedIcon  sx={{ fontSize: 38, color: 'gray' }} />
                            </Link>
                        </span>
                           }

                        <span style={{ paddingLeft: 10 }}>
                            <Link to='/'>
                                <LogoutIcon onClick={signOut} sx={{ fontSize: 38, color: 'gray' }} />
                            </Link>
                        </span>   
                    </div>
                </footer>
            )}
        </Authenticator>
    )
}

export default Footer