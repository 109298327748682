import React from 'react';
import logo from '../homerun-derby-pic.jpg'




const Header = () => {
    return (
        
       
         
           <div style={{display: 'flex', justifyContent: 'center'}} >
          
                <img src={logo} alt="HR Derby" style={{height: 200}}  ></img>
                
          </div>   
    
       
    )
}

export default Header