import React from 'react'


const Home = () => {
    return (
     
      <div style={{ textAlign: 'center' }}>
      <h2 style={{ color: '#51C786', fontWeight: 'bold' }}>
        2024 Home Run Derby Challenge
      </h2>

      <div style={{ textAlign: 'left', paddingLeft: 10 }}>
        <h6 style={{ color: '#51C786', fontWeight: 'bold' }}>Rules: </h6>
        <p> Draft 7 players from Major League Baseball </p>
        <p> Top 5 players home runs  count towards total </p>
        <p> No Trades </p>
        <p> No Free Agency </p>
        <p> Top 2 Places Payout 1st: $150 2nd: $50 3rd: $25</p>
        <p style={{fontWeight: 'bold' }}>
          Homerun totals will be updated each day by 9am
        </p>
      </div>
    </div>
    )}
 



export default Home;
