import React, { useState, useEffect } from 'react';

import { getOwner } from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { Link, useParams } from 'react-router-dom';
import { Container, Grid } from '@mui/material';

import PlayersDetailsCard from '../components/PlayersDetailsCard';

import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';


import CardContent from '@mui/material/CardContent';
import { AvatarGroup, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import loadingBaseball from '../spinningBaseball.gif';

import blackApple from '../black_apple.jpg'
import theDude from '../The_Dude5.jpg';
import jerry from '../jerry.jpg';
import pillow from '../pillow.jpg';
import smalls from '../smalls.jpg';
import logo from '../homerun-derby-pic.jpg';
import shirt from '../shirt.jpg'
import ups from '../ups.jpg'
import greg from '../greg.jpg'

const TeamDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setTeam] = useState();
    const [teamName, setName] = useState();
    const [teamTeamName, setTeamTeamName] = useState();
    const [players, setPlayers] = useState([])
    const { teamID } = useParams()


    useEffect(() => {
        fetchTeam();
    }, []);


    async function fetchTeam() {
        try {
            setIsLoading(true);
            const oneTeam = await API.graphql({ query: getOwner, variables: { id: teamID } });
            const teamDetail = oneTeam.data.getOwner
            const emailAddress = teamDetail.emailAdress
            const teamName = teamDetail.ownerName
            const teamTeamName = teamDetail.ownerTeamName
            const players = teamDetail.players.items.reverse()
            console.log(players)

            setTeam(emailAddress)
            setName(teamName)
            setTeamTeamName(teamTeamName)
            setPlayers(players)
            setIsLoading(false);

        } catch (error) {
            console.log('error fetching team data', error)
        }
    }
    const hrTotal = players.slice(0, 5)
    var result = hrTotal.reduce(function (tot, arr) {
        return tot + arr.totalHR;
    }, 0);



    return (

        <div >

            <div>
                {isLoading ? (<div><span style={{ display: 'flex', justifyContent: 'center' }}><img src={loadingBaseball} style={{ height: 200, width: 200 }}></img></span></div>) : " "}
                {isLoading ? (<div style={{ textAlign: 'center' }}> loading........</div>) : " "}
                {isLoading ? " " : (<Card style={{ backgroundColor: 'black' }}>
                    <CardHeader
                        avatar={
                            <AvatarGroup style={{ spacing: 'large' }} >

                                <Avatar src={teamTeamName === 'Jerrys Kids' ? jerry :
                                             teamTeamName === 'The Dude' ? theDude : 
                                             teamTeamName === 'Blapples' ? blackApple :
                                             teamTeamName === 'Killing Me Smalls' ? smalls :
                                             teamTeamName === 'My Pillow Guy' ? pillow :
                                             teamTeamName === 'Valeries' ? ups :
                                             teamTeamName === 'The Shirt' ? shirt :
                                             teamTeamName === 'The Greggy Bunch' ? greg :

                                              logo} sx={{ width: 120, height: 120 }} variant="square" />
                            </AvatarGroup>

                        }

                       
                        title={<Typography sx={{ color: 'gray', fontWeight: 'bold' }}>{teamTeamName}</Typography>}
                        subheader={<Typography sx={{ color: 'gray' }}>{teamName}</Typography>}


                    />

                </Card>)}
            </div>
            {isLoading ? " " : (<div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 22, color: '#51C786' }} >
                Total Net HR {result}
            </div>)}

            <Container   >
                <Grid container spacing={1}>
                    {

                        players.map((player, index) => (
                            <Grid item key={player.id} xs={12} md={12} lg={12}>
                                <PlayersDetailsCard player={player} index={index} />
                            </Grid>

                        ))}
                </Grid>
            </Container>

        </div>


    )
}

export default TeamDetails;