import React, { useState, useEffect } from 'react';

import { listOwners } from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { updateOwner } from '../graphql/mutations'
import '@aws-amplify/ui-react/styles.css';

import UpdateCard from '../components/updateCard';
import { Container, Grid } from '@mui/material';
import loadingBaseball from '../spinningBaseball.gif';


const Update = () => {

    const [teams, setTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [updatedDate, setUpdatedDate] = useState();
    
    //useEffect hook to trigger the call to fetchTeams everytime Team page is loading
    useEffect(() => {
        fetchTeams();
    }, []);

    // fetchTeams()
    // gets the list of Teams from the query of listOwners 
    // and assigns the array to teamList and 
    // then sets team array to the data through setTeam
    async function fetchTeams() {
        try {
            setIsLoading(true);
            setIsLoaded(false);
          
            const teamData = await API.graphql({ query: listOwners });
            const teamList = teamData.data.listOwners.items;
            teamList.sort((secondTeam, firstTeam) => firstTeam.teamTotalHR - secondTeam.teamTotalHR);
            const updatedDate = teamList[1].updatedAt;

            setTeams(teamList);
            setUpdatedDate(updatedDate);
            console.log(teamList);
            console.log(teamList[1].ownerName);
            console.log(teamList[1].updatedAt);
            
            setIsLoaded(true);
            setIsLoading(false);
        } catch (error) {
            console.log('error fetching team data', error)
        }
    }

    async function updateOwner(id, totalHR) {
        try {
          await API.graphql(graphqlOperation(updateOwner, {input: id, totalHR}))
        } catch (err) {
          console.log('error updating owner data:', err)
        }
      }

    function formatDate(string){
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([],options);
    }

    
    

    return (
        <div>
            {isLoading ? (<div><span style={{ display: 'flex', justifyContent: 'center' }}><img src={loadingBaseball} style={{ height: 200, width: 200 }}></img></span></div>) : " "}
            {isLoading ? (<div style={{ textAlign: 'center' }}> loading........</div>) : " "}
            {isLoaded ? (<div style={{ textAlign: 'center' }}> Updated   {formatDate(updatedDate)} </div>) : " "}
            
            <Container  >
                <Grid container spacing={1} justifyContent="center">
                    {

                        teams.map((team, index) => (
                            <Grid item key={team.id} xs={12} md={12} lg={12}>
                                <UpdateCard team={team} index={index} />
                            </Grid>

                        ))}
                </Grid>
            </Container>
        </div>


    )
}

export default Update;
