import React, { useState, useEffect } from 'react';
import { API } from "aws-amplify";
import { updateOwner } from '../graphql/mutations'

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { AvatarGroup, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';

import logo from '../homerun-derby-pic.jpg';
import blackApple from '../black_apple.jpg'
import theDude from '../The_Dude5.jpg';
import jerry from '../jerry.jpg';
import pillow from '../pillow.jpg';
import smalls from '../smalls.jpg';
import shirt from '../shirt.jpg'
import ups from '../ups.jpg'


function UpdateCard({ team, index }) {


    const teamLogo = logo
    const [hr, setHR] = useState('')

  

    function handleChange(e) {
        setHR(e.target.value);
    }

    function upDateHR(id, totalHR, value) {
        const updateHR = {
            id: id,
            teamTotalHR: parseInt(value)

        };

        console.log(updateHR);
        API.graphql({ query: updateOwner, variables: { input: updateHR } });;
    }

    return (

        <div>

            <Card style={{ backgroundColor: 'lightGray' }}>
                <CardHeader
                    avatar={
                        <AvatarGroup style={{ spacing: 'large' }} >
                            <Avatar sx={{ bgcolor: 'red', width: 24, height: 24 }} aria-label="recipe">{index + 1}</Avatar>

                            <Avatar src={team.id === '6' ? pillow : team.id === '4' ? smalls :
                                team.id === '1' ? jerry :
                                    team.id === '2' ? theDude :
                                        team.id === '3' ? blackApple :
                                            team.id === '5' ? shirt :
                                                team.id === '8' ? ups :
                                                    logo} sx={{ width: 56, height: 56 }} />
                        </AvatarGroup>

                    }

                />
                <CardContent style={{ textAlign: 'center', fontWeight: 'bold', padding: 2 }} >
                    <div>{team.ownerTeamName} </div>

                    <input style={{ width: '40px', textAlign: 'center' }}
                        placeholder={team.teamTotalHR}
                        type='int'
                        onChange={handleChange}
                        value={hr}

                    />

                    <button disabled={!hr} style={{ marginLeft: '10px' }} onClick={() => (upDateHR(team.id, team.teamTotalHR, hr))} >Update</button>
                </CardContent>
            </Card>
        </div>
    )
}

export default UpdateCard;