import React, { useState, useEffect } from 'react';

import { listPlayers } from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import UpdatePlayerCard from '../components/UpdatePlayerCard';

import { Container, Grid } from '@mui/material';
import loadingBaseball from '../spinningBaseball.gif';


const UpdatePlayerHR = () => {

    const [players, setPlayers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        fetchPlayers();
    }, []);

    async function fetchPlayers() {
        try {
            setIsLoading(true);
            setIsLoaded(false);

            const playerData = await API.graphql({ query: listPlayers });
            const playerList = playerData.data.listPlayers.items;
            const sortedList = sortPlayersbyName(playerList)
            setPlayers(sortedList);
            console.log(sortedList);
            setIsLoaded(true);
            setIsLoading(false);
        } catch (error) {
            console.log('error fetching player data', error)
        }
    }

    function sortPlayersbyName(playersL) {
        return playersL.sort((a, b) => a.playerName.localeCompare(b.playerName))

    }

    return (
        <div>
            {isLoading ? (<div><span style={{ display: 'flex', justifyContent: 'center' }}><img src={loadingBaseball} style={{ height: 200, width: 200 }}></img></span></div>) : " "}
            {isLoading ? (<div style={{ textAlign: 'center' }}> loading........</div>) : " "}
           

            <Container  >
                <Grid container spacing={1} justifyContent="center">
                    {
                       
                        players.map((player, index) => (
                            <Grid item key={player.id} xs={12} md={12} lg={12}>
                                <UpdatePlayerCard player={player} index={index} />
                            </Grid>

                        ))}
                </Grid>
            </Container>
        </div>


    )
}

export default UpdatePlayerHR;