import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'black',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));



function AllPlayerDetailsCard({ player, index }) {
    return (
        <div>

            <Card style={{ height: 40, backgroundColor: index < 5 ? 'lightgray' : 'darkgray' }}>

             
                <CardContent  >
                    
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    
                    <Grid item xs={6} >
                    <Item style={{backgroundColor: index < 5 ? 'lightgray' : 'darkgray' }}>{player.playerName}</Item>
                    </Grid>
                    <Grid item xs={6}>
                    <Item style={{backgroundColor: index < 5 ? 'lightgray' : 'darkgray' }}>HR TOTAL: {player.totalHR}</Item>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        </div >
    )
}

export default AllPlayerDetailsCard;
