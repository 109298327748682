import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { AvatarGroup, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import logo from '../homerun-derby-pic.jpg';
import blackApple from '../black_apple.jpg'
import theDude from '../The_Dude5.jpg';
import jerry from '../jerry.jpg';
import pillow from '../pillow.jpg';
import smalls from '../smalls.jpg';
import shirt from '../shirt.jpg'
import ups from '../ups.jpg'
import greg from '../greg.jpg'

import PersonIcon from '@mui/icons-material/Person';






function TeamCard({ team, index }) {

    const teamLogo = logo

    return (


        <div>
            
            <Card style={{ backgroundColor: 'lightGray' }}>
                <CardHeader
                    avatar={
                        <AvatarGroup style={{ spacing: 'large' }} >
                            <Avatar sx={{ bgcolor: 'red', width: 24, height: 24 }} aria-label="recipe">{index + 1}</Avatar>

                            <Avatar src={team.id === '6' ? pillow : team.id === '4' ? smalls :
                                                                    team.id === '1' ? jerry :
                                                                    team.id === '2' ? theDude :
                                                                    team.id === '3' ? blackApple :
                                                                    team.id === '5' ? shirt :
                                                                    team.id === '8' ? ups :
                                                                    team.id === '7' ? greg :
                                                                    
                                                                    
                                                                    logo} sx={{ width: 56, height: 56 }} />
                        </AvatarGroup>

                    }

                    action={
                        <Link to={`/teamDetails/${team.id}`}>
                            <IconButton>
                                <PersonIcon />
                            </IconButton>
                        </Link>

                    }
                    title={team.ownerTeamName}
                    subheader={team.ownerName}
                />
                <CardContent style={{ textAlign: 'center', fontWeight: 'bold', padding: 2 }} >
                    NET HR TOTAL: {team.teamTotalHR}
                </CardContent>
            </Card>
        </div>
    )
}

export default TeamCard;

