import React from 'react';



const DailyHomeRunTracker = () => {
  

  return (
    

    <div style={{ textAlign: 'center' }}>

      <h3 style={{ color: '#51C786', fontWeight: 'bold' }}>
        2023 Daily Home Run Tracker*  </h3>
        <p>* home runs from this page will be updated next day</p>
     
       <span ><iframe src="https://www.onlyhomers.com/daily" style={{ height: 540, width: 400 }}> </iframe></span>

      
    </div>
  )
}




export default DailyHomeRunTracker;
