import React, { useState, useEffect } from 'react';
import { API } from "aws-amplify";
import { updatePlayer } from '../graphql/mutations'

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { AvatarGroup, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';



function UpdatePlayerCard({ player, index }) {

    const [hr, setHR] = useState('')
    const [dailyHR, setDailyHR] = useState('')

    function handleChange(e) {
        setHR(e.target.value);
    }

    function handleDailyChange(e) {
        setDailyHR(e.target.value);

    }

    function upDateHR(id, dailyHR, hr) {
        const updateHR = {
            id: id,
            totalHR: parseInt(hr),
            dailyHR: parseInt(dailyHR)

        };

        console.log(updateHR);
        API.graphql({ query: updatePlayer, variables: { input: updateHR } });;
    }

    function SubmitButton(){
        if(hr && dailyHR){
            return <button style={{ marginLeft: '10px' }} onClick={() => (upDateHR(player.id, dailyHR, hr))} >Update</button>

        }else {
            return <button type="button" disabled >Update</button>
        }
    }

    

    return (

        <div>

            <Card style={{ backgroundColor: 'lightGray' }}>
                <CardHeader


                />
                <CardContent style={{ textAlign: 'center', fontWeight: 'bold', padding: 2 }} >
                    <div>{player.playerName} </div>
                    <br></br>
                    <label for="Daily HR ">Daily HR</label>
                    <input style={{ width: '40px', textAlign: 'center' }}

                        placeholder={player.dailyHR}
                        name={"Daily HR"}
                        onChange={handleDailyChange}
                        value={dailyHR}

                    />
                    <label for="Total HR  ">Total HR</label>
                    <input style={{ width: '40px', textAlign: 'center' }}

                        placeholder={player.totalHR}
                        name={"Total HR"}
                        type='int'
                        onChange={handleChange}
                        value={hr}

                    />

                    <SubmitButton/>
                </CardContent>
            </Card>
        </div>
    )
}

export default UpdatePlayerCard;