import React, { useState, useEffect } from 'react';

import { getOwner, listOwners } from '../graphql/queries';
import { API, } from 'aws-amplify';

import '@aws-amplify/ui-react/styles.css';
import { Container, Grid } from '@mui/material';

import AllPlayersDetailsCard from '../components/AllPlayersDetailsCard';
import Card from '@mui/material/Card';
import loadingBaseball from '../spinningBaseball.gif';


const AllPlayers = () => {

    const [teamTeamName, setTeamTeamName] = useState();
    const [teamTeamName2, setTeamTeamName2] = useState();
    const [teamTeamName3, setTeamTeamName3] = useState();
    const [teamTeamName4, setTeamTeamName4] = useState();
    const [teamTeamName5, setTeamTeamName5] = useState();
    const [teamTeamName6, setTeamTeamName6] = useState();
    const [teamTeamName7, setTeamTeamName7] = useState();
    const [teamTeamName8, setTeamTeamName8] = useState();
    const [teamTeamName9, setTeamTeamName9] = useState();

    const [totalHR, setTotalHR] = useState();
    const [totalHR2, setTotalHR2] = useState();
    const [totalHR3, setTotalHR3] = useState();
    const [totalHR4, setTotalHR4] = useState();
    const [totalHR5, setTotalHR5] = useState();
    const [totalHR6, setTotalHR6] = useState();
    const [totalHR7, setTotalHR7] = useState();
    const [totalHR8, setTotalHR8] = useState();
    const [totalHR9, setTotalHR9] = useState();


    const [players, setPlayers] = useState([])
    const [players2, setPlayers2] = useState([])
    const [players3, setPlayers3] = useState([])
    const [players4, setPlayers4] = useState([])
    const [players5, setPlayers5] = useState([])
    const [players6, setPlayers6] = useState([])
    const [players7, setPlayers7] = useState([])
    const [players8, setPlayers8] = useState([])
    const [players9, setPlayers9] = useState([])

    const [isLoading, setIsLoading] = useState(false);
   



    useEffect(() => {
        fetchTeam();
    }, []);

   


    async function fetchTeam() {
        try {
            setIsLoading(true);
            const teamOne = await API.graphql({ query: getOwner, variables: { id: 1} });
            const teamTwo = await API.graphql({ query: getOwner, variables: { id: 2 } });
            const teamThree = await API.graphql({ query: getOwner, variables: { id: 3 } });
            const teamFour = await API.graphql({ query: getOwner, variables: { id: 4 } });
            const teamFive = await API.graphql({ query: getOwner, variables: { id: 5 } });
            const teamSix = await API.graphql({ query: getOwner, variables: { id: 6 } });
            const teamSeven = await API.graphql({ query: getOwner, variables: { id: 7 } });
            const teamEight = await API.graphql({ query: getOwner, variables: { id: 8 } });
            const teamNine = await API.graphql({ query: getOwner, variables: { id: 9 } });
       
            const teamDetail = teamOne.data.getOwner
            const teamDetail2 = teamTwo.data.getOwner
            const teamDetail3 = teamThree.data.getOwner
            const teamDetail4 = teamFour.data.getOwner
            const teamDetail5 = teamFive.data.getOwner
            const teamDetail6 = teamSix.data.getOwner
            const teamDetail7 = teamSeven.data.getOwner
            const teamDetail8 = teamEight.data.getOwner
            const teamDetail9 = teamNine.data.getOwner

            const teamTeamName = teamDetail.ownerTeamName
            const teamTeamName2 = teamDetail2.ownerTeamName
            const teamTeamName3 = teamDetail3.ownerTeamName
            const teamTeamName4 = teamDetail4.ownerTeamName
            const teamTeamName5 = teamDetail5.ownerTeamName
            const teamTeamName6 = teamDetail6.ownerTeamName
            const teamTeamName7 = teamDetail7.ownerTeamName
            const teamTeamName8 = teamDetail8.ownerTeamName
            const teamTeamName9 = teamDetail9.ownerTeamName

            const totalHR = teamDetail.teamTotalHR
            const totalHR2 = teamDetail2.teamTotalHR
            const totalHR3 = teamDetail3.teamTotalHR
            const totalHR4 = teamDetail4.teamTotalHR
            const totalHR5 = teamDetail5.teamTotalHR
            const totalHR6 = teamDetail6.teamTotalHR
            const totalHR7 = teamDetail7.teamTotalHR
            const totalHR8 = teamDetail8.teamTotalHR
            const totalHR9 = teamDetail9.teamTotalHR

            const players = teamDetail.players.items.reverse()
            const players2 = teamDetail2.players.items.reverse()
            const players3 = teamDetail3.players.items.reverse()
            const players4 = teamDetail4.players.items.reverse()
            const players5 = teamDetail5.players.items.reverse()
            const players6 = teamDetail6.players.items.reverse()
            const players7 = teamDetail7.players.items.reverse()
            const players8 = teamDetail8.players.items.reverse()
            const players9 = teamDetail9.players.items.reverse()
            

            setTeamTeamName(teamTeamName)
            setTeamTeamName2(teamTeamName2)
            setTeamTeamName3(teamTeamName3)
            setTeamTeamName4(teamTeamName4)
            setTeamTeamName5(teamTeamName5)
            setTeamTeamName6(teamTeamName6)
            setTeamTeamName7(teamTeamName7)
            setTeamTeamName8(teamTeamName8)
            setTeamTeamName9(teamTeamName9)

            setTotalHR(totalHR)
            setTotalHR2(totalHR2)
            setTotalHR3(totalHR3)
            setTotalHR4(totalHR4)
            setTotalHR5(totalHR5)
            setTotalHR6(totalHR6)
            setTotalHR7(totalHR7)
            setTotalHR8(totalHR8)
            setTotalHR9(totalHR9)




            setPlayers(players)
            setPlayers2(players2)
            setPlayers3(players3)
            setPlayers4(players4)
            setPlayers5(players5)
            setPlayers6(players6)
            setPlayers7(players7)
            setPlayers8(players8)
            setPlayers9(players9)

            setIsLoading(false);

        } catch (error) {
            console.log('error fetching team data', error)
        }
    }

    


    return (

        <div >

            {isLoading ? (<div><span style={{ display: 'flex', justifyContent: 'center' }}><img src={loadingBaseball} style={{ height: 200, width: 200 }}></img></span></div>) : " "}
            {isLoading ? (<div style={{ textAlign: 'center' }}> loading........</div>) : " "}
            {isLoading ? " " : (
            <span >    
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Team: {teamTeamName}</div> 
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Total Net HR: {totalHR}</div> 
            </span>
            )}
            <Container   >
                <Card >
                  
                    {

                        players.map((player, index) => (
                            <Grid item key={player.id} xs={12} md={6} lg={4}>
                                <AllPlayersDetailsCard player={player} index={index} />
                            </Grid>

                        ))}
                </Card>
            </Container>
            {isLoading ? " " : (
            <span >    
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Team: {teamTeamName2}</div> 
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Total Net HR: {totalHR2}</div> 
            </span>
            )}
            <Container   >
                <Card >
                    {

                        players2.map((player, index) => (
                            <Grid item key={player.id} xs={12} md={6} lg={4}>
                                <AllPlayersDetailsCard player={player} index={index} />
                            </Grid>

                        ))}
                </Card>
            </Container>
            {isLoading ? " " : (
            <span >    
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Team: {teamTeamName3}</div> 
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Total Net HR: {totalHR3}</div> 
            </span>
            )}
            <Container   >
                <Card >
                  
                    {

                        players3.map((player, index) => (
                            <Grid item key={player.id} xs={12} md={6} lg={4}>
                                <AllPlayersDetailsCard player={player} index={index} />
                            </Grid>

                        ))}
                </Card>
            </Container>
            {isLoading ? " " : (
            <span >    
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Team: {teamTeamName4}</div> 
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Total Net HR: {totalHR4}</div> 
            </span>
           )}
            <Container   >
                <Card >
                  
                    {

                        players4.map((player, index) => (
                            <Grid item key={player.id} xs={12} md={6} lg={4}>
                                <AllPlayersDetailsCard player={player} index={index} />
                            </Grid>

                        ))}
                </Card>
            </Container>
            {isLoading ? " " : (
            <span >    
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Team: {teamTeamName5}</div> 
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Total Net HR: {totalHR5}</div> 
            </span>
            )}
            <Container   >
                <Card >
                  
                    {

                        players5.map((player, index) => (
                            <Grid item key={player.id} xs={12} md={6} lg={4}>
                                <AllPlayersDetailsCard player={player} index={index} />
                            </Grid>

                        ))}
                </Card>
            </Container>
            {isLoading ? " " : (
            <span >    
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Team: {teamTeamName6}</div> 
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Total Net HR: {totalHR6}</div> 
            </span>
            )}
            <Container   >
                <Card >
                  
                    {

                        players6.map((player, index) => (
                            <Grid item key={player.id} xs={12} md={6} lg={4}>
                                <AllPlayersDetailsCard player={player} index={index} />
                            </Grid>

                        ))}
                </Card>
            </Container>
            {isLoading ? " " : (
            <span >    
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Team: {teamTeamName7}</div> 
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Total Net HR: {totalHR7}</div> 
            </span>
            )}
            <Container   >
                <Card >
                  
                    {

                        players7.map((player, index) => (
                            <Grid item key={player.id} xs={12} md={6} lg={4}>
                                <AllPlayersDetailsCard player={player} index={index} />
                            </Grid>

                        ))}
                </Card>
            </Container>
            {isLoading ? " " : (
           <span >    
           <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Team: {teamTeamName8}</div> 
           <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Total Net HR: {totalHR8}</div> 
           </span>
            )}
            <Container   >
                <Card >
                  
                    {

                        players8.map((player, index) => (
                            <Grid item key={player.id} xs={12} md={6} lg={4}>
                                <AllPlayersDetailsCard player={player} index={index} />
                            </Grid>

                        ))}
                </Card>
            </Container>

            {isLoading ? " " : (
            <span >    
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Team: {teamTeamName9}</div> 
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Total Net HR: {totalHR9}</div> 
            </span>
            )}
            <Container   >
                <Card >
                    {

                        players9.map((player, index) => (
                            <Grid item key={player.id} xs={12} md={6} lg={4}>
                                <AllPlayersDetailsCard player={player} index={index} />
                            </Grid>

                        ))}
                </Card>
            </Container>                
            
            
        </div>


    )
}

export default AllPlayers;
