import React from 'react';
import {
  BrowserRouter as
    Router,
  Routes,
  Route
} from "react-router-dom";
// Amplify
import Amplify from "aws-amplify";

// Pages

import Teams from "./pages/Teams";
import Update from "./pages/Update";
import Error from "./pages/Error";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import AllPlayers from "./pages/AllPlayers";
import History from "./pages/History";
import TeamDetails from "./pages/TeamDetails";
import UpdatePlayerHR from "./pages/UpdatePlayerHR";
import DailyHomeRunTracker from "./pages/DailyHomeRunTracker";

// Components
import Header from "./components/Header"
import Footer from "./components/Footer"

// Amplify Configurations
import awsExports from "./aws-exports";


Amplify.configure(awsExports);


const App = () => {
  return (

    <Router>

      <Header />

      <Routes>
        <Route path="/" exact element={<SignIn />} />

        <Route path="/home" exact element={<Home />} />

        <Route path="/teams" exact element={<Teams />} />

        <Route path="/updateTeamHR" exact element={<Update />} />

        <Route path="/updatePlayerHR" exact element={<UpdatePlayerHR />} />

        <Route path='/teamDetails/:teamID' element={<TeamDetails />} />

        <Route path="*" exact element={<Error />} />

        <Route path="/allPlayers" exact element={<AllPlayers />} />

        <Route path="/history" exact element={<History />} />

        <Route path="/dailyHomeRuns" exact element={<DailyHomeRunTracker />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;